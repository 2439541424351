<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
            spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs8 pt-8 text-left>
            <span style="font-size: 25px; font-weight: bolder">MY APPLICATIONS
            </span>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable dense outlined readonly v-model="fromDate" v-bind="attrs" background-color="white"
                  v-on="on" @click:clear="from = null" style="color: black">
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="custom-label-text" style="color: black; font-size: 14px">
                      From Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="fromDate" color="#13736f" @change="menu1 = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" max-width="290" elevation="0">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable label="To Date" readonly dense background-color="white" color="#13736f" outlined
                  v-model="toDate" v-bind="attrs" v-on="on" @click:clear="from = null">
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="custom-label-text" style="color: black; font-size: 14px">
                      To Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="toDate" color="#13736f" @change="menu2 = false" :min="fromDate"></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-4 v-for="(item, i) in list" :key="i">
        <v-layout wrap>
          <v-flex xs12>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex pt-2 style="background-color: black" xs12 pl-4 pb-2>
                  <span class="heading" style="color: white; font-size: 22px">{{ item.campid.campName }} </span>&nbsp;
                  <span class="heading" style="color: white; font-size: 22px">({{ item.campid.divisionid.officeName }}
                    )</span>&nbsp;
                  <span class="heading" style="color: white; font-size: 22px">Application No:&nbsp;&nbsp;{{
                    item.applicationNo }}</span>&nbsp;
                </v-flex>

                <v-flex xs3 pt-3 pb-3 pl-3>
                  <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
                </v-flex>
                <v-flex xs9 pt-3>
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex pl-4 xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Application Number
                          </span>
                          <br />
                          <span>{{ item.applicationNo }}</span>
                        </v-flex>

                        <v-flex xs3  text-left pb-4>
                          <span class="subheading" style="font-size: 16px;">
                            Date Of Application
                          </span>
                          <br />
                          <span>{{
                            formatDate(item.created_at)
                          }}</span>
                        </v-flex>

                        <v-flex xs4  text-left >
                          <span class="subheading" style="font-size: 16px;">
                           Application Status
                          </span>
                          <br />
                          <span></span>
                        </v-flex>

                        <v-flex pl-4 xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Applicant
                          </span>
                          <br />
                          <span>{{ item.applicantname }}</span>
                        </v-flex>
                        <v-flex  xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Phone Number
                          </span>
                          <br />
                          <span>{{ item.userid.mobNumber }}</span>
                        </v-flex>

                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Disrict
                          </span>
                          <br />
                          <span>{{ item.district }}</span>
                        </v-flex>
                        <v-flex xs3 text-left pb-4>
                          <span class="subheading" style="font-size: 16px">
                            Applicant Category
                          </span>
                          <br />
                          <span>{{ item.applicantCategory }}</span>
                        </v-flex>

                        <v-flex pl-4 xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Requested Date
                          </span>
                          <br />
                          <span> {{ formatDate(item.startDate) }} </span>
                        </v-flex>
                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px ;">
                            Duration of Camp
                          </span>
                          <br />
                          <span>{{ item.campDuration }}</span>
                        </v-flex>
                        <v-flex xs3 pl-4 text-left v-if="item.divisonApprovalStatus === 'Success' &&
                          item.superAdminApprovalStatus === 'Success'
                          ">
                          <span class="subheading" style="font-size: 16px; color: red">
                            Approved Date
                          </span>
                          <br />
                          <span style="color: red">{{
                            formatDate(item.startDate)
                          }}</span>
                        </v-flex>
                        
                        <v-flex xs3 pl-3 text-left v-if="item.divisonApprovalStatus === 'rejected'">
                          <span style="font-size: 16px;color: red;">
                            REASON FOR REJECTION
                          </span>
                          <br />
                          <span style="color: red;">{{ item.rejectReasondfo }}</span>
                        </v-flex>
                        <v-flex xs3 pl-3 text-left v-if="item.superAdminApprovalStatus === 'rejected'">
                          <span style="font-size: 16px;color: red;">
                            REASON FOR REJECTION
                          </span>
                          <br />
                          <span style="color: red;">{{ item.rejectReasonadmin }}</span>
                        </v-flex>
                        <!-- <v-flex pl-4 xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Staff/Group Leader

                          </span>
                          <br />
                          <span>{{ item.campid.totalMembers }}</span>
                        </v-flex>
                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Male Members

                          </span>
                          <br />
                          <span>{{ item.campid.membersLimit.male }}</span>
                        </v-flex>

                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Female Members

                          </span>
                          <br />
                          <span>{{ item.campid.membersLimit.female }}</span>

                        </v-flex>
                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Transgender Members

                          </span>
                          <br />
                          <span>{{ item.campid.membersLimit.transgender }}</span>

                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 align-self-center>
                      <v-layout wrap justify-start>
                        <v-flex xs2 pb-4 text-center>
                          <v-btn @click="
                            $router.push('/CampDetailedView?id=' + item._id)
                            " plain class="text-capitalize" color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>
                        </v-flex>
                        <!-- <v-flex xs2 pb-4 text-center>
                          <v-btn @click="navigateToDetailedView(item._id)" plain class="text-capitalize" color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>
                        </v-flex> -->

                        <v-flex xs3 pb-4 text-center>
                          <template v-if="item.cancelRequest === 'Yes'">
                            <span style="color: red;">Requested For Cancellation</span>
                          </template>
                          <template v-else>
                            <v-btn plain class="text-capitalize"
                              @click="setCurIdToDelete(item._id), (deletedialog = true)" color="red">
                              <v-icon color="red">mdi-cancel</v-icon>&nbsp;
                              <span>CANCEL</span>
                            </v-btn>
                          </template>
                        </v-flex>


                        <v-flex xs4 pt-1 text-left v-if="item.editStatus === 'Disable'">
                          <span v-if="item.divisonApprovalStatus === 'Pending'">
                            Application Submitted
                          </span>
                          <span v-else-if="item.divisonApprovalStatus === 'rejected'
                            ">
                            Rejected By Division Head
                          </span>
                          <span v-else>Approved By Division Head</span>

                          <span v-if="item.divisonApprovalStatus === 'Success' &&
                            item.superAdminApprovalStatus === 'Success'
                            ">
                            Approved By Super Admin
                          </span>
                          &nbsp;
                          <v-icon color="green">mdi-check-circle-outline</v-icon>
                        </v-flex>

                        <v-flex xs2 pb-4 text-center v-else>
                          <v-btn @click="
                            $router.push({
                              path: '/editbooking',
                              query: {
                                id: item._id,
                                district: item.district,
                              },
                            })
                            " plain class="text-capitalize" color="orange">
                            <v-icon color="orange">mdi-pencil</v-icon>&nbsp;
                            <span>EDIT</span>
                          </v-btn>
                        </v-flex>
                        <!-- <v-flex xs2 pb-4 text-center v-else>
                          <v-btn @click="navigateToEditBooking(item._id, item.district)" plain class="text-capitalize"
                            color="orange">
                            <v-icon color="orange">mdi-pencil</v-icon>&nbsp;
                            <span>EDIT</span>
                          </v-btn>
                        </v-flex> -->

                        <!-- <v-flex
                          xs3
                          pl-4
                          v-if="item.divisonApprovalStatus === 'rejected'"
                        >
                          <span>Application Rejected by Division Head</span>
                          <v-icon color="red">mdi-close-circle-outline</v-icon>
                        </v-flex> -->

                        <v-flex xs3 pl-4 v-if="item.superAdminApprovalStatus === 'rejected'">
                          <span>Application Rejected by Admin</span>
                          <v-icon color="red">mdi-close-circle-outline</v-icon>
                        </v-flex>

                        <!-- <v-flex
                          xs3
                          pl-4
                          v-if="
                            item.divisonApprovalStatus === 'Success' &&
                            item.superAdminApprovalStatus === 'Success'
                          "
                        >
                          <v-btn
                            color="red"
                            @click="getAcknowledgement(item._id)"
                          >
                            <span style="color: aliceblue">
                              Acknowledgement
                            </span>
                          </v-btn>
                        </v-flex> -->
                        <v-flex xs12 pl-4 pb-4>
                          <v-btn v-if="item.divisonApprovalStatus === 'Success' &&
                            item.superAdminApprovalStatus == 'Success'
                            " color="red" @click="getAcknowledgement(item._id)">
                            <span style="color: aliceblue">
                              Sanction Order
                            </span>
                            <v-icon color="white">mdi-download</v-icon>
                          </v-btn>
                          <!-- <v-btn v-if="item.divisonApprovalStatus === 'rejected'" color="red"
                            @click="getAcknowledgementrej(item._id)">
                            <span style="color: aliceblue">
                              Cancellation Order
                            </span>
                            <v-icon color="white">mdi-download</v-icon>
                          </v-btn> -->
                          <v-btn v-if="item.divisonApprovalStatus === 'rejected'" color="red"
                            @click="getAcknowledgementrejctionorder(item._id)">
                            <span style="color: aliceblue">
                              Rejection Order
                            </span>
                            <v-icon color="white">mdi-download</v-icon>
                          </v-btn>
                          <v-btn v-if="item.superAdminApprovalStatus === 'rejected'" color="red"
                            @click="getAcknowledgementrejctionorder(item._id)">
                            <span style="color: aliceblue">
                              Rejection Order
                            </span>
                            <v-icon color="white">mdi-download</v-icon>
                          </v-btn>
                        </v-flex>

                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
        <v-card>
          <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you want
            to request for Application Cancellation?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="deleteItem()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout wrap>
      <v-flex text-center xs12 v-if="list.length < 1">
        <span>No Applications !</span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 v-if="list.length > 0">
        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      itemArray2: ["Vegeterian", "Non-vegetarian"],
      // cdate: moment().format("YYYY-MM-DD"),
      curIdToDelete: null,
      name: null,
      editdialog: false,
      barlist: [],
      edit: [],
      userProfileTabs: null,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],

      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      cancellationRequested: false,
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: null,

      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      currentPage: 1,
      showSnackBar: false,
      info: [],
      dialog2: false,
      viewdialog: false,
    };
  },

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
  },
  computed: {
    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },

  methods: {
    setCurIdToDelete(id) {
      this.curIdToDelete = id;
    },
    navigateToDetailedView(id) {
      const encryptedId = CryptoJS.AES.encrypt(id, 'encryptionSecret1234)(*&^%$#:">?")qws').toString();
      this.$router.push({ path: `/CampDetailedView?id=${encryptedId}` });
    },
    navigateToEditBooking(id, district) {
    // Encrypt the ID before navigating
    const encryptedId = CryptoJS.AES.encrypt(id, 'encryptionSecret').toString();
    const encryptedDistrict = CryptoJS.AES.encrypt(district, 'encryptionSecret').toString();
    this.$router.push({
      path: '/editbooking',
      query: {
        id: encryptedId,
        district: encryptedDistrict
      },
    });
  },
    setCurIdToDelete2(id) {
      this.curIdToDelete2 = id;
    },
    getAcknowledgement(appid) {
      const token = localStorage.getItem("token");
      const downloadUrl = "/sanction/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: appid,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Sanction Order.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    getAcknowledgementrej(appid) {
      const token = localStorage.getItem("token");
      const downloadUrl = "/rejectby/divsion/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: appid,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "acknowledgment.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    getAcknowledgementrejctionorder(appid) {
      const token = localStorage.getItem("token");
      const downloadUrl = "/rejection/order";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: appid,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Rejection Order.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },
    getAcknowledgementreject(appid) {
      const token = localStorage.getItem("token");
      const downloadUrl = "/rejectby/admin/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: appid,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "acknowledgment.pdf");
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },

    deleteItem() {
      const idToDelete = this.curIdToDelete;

      if (!idToDelete) {
        return;
      }

      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: idToDelete,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;

            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },


    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getList() {
      this.appLoading = true;
      var formattedFromDate = new Date(this.fromDate).toISOString();
      if (this.toDate != null) {
        var formattedToDate = new Date(this.toDate).toISOString();
      }
      axios({
        method: "POST",
        url: "/user/applications",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: "yes",
          categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: formattedFromDate,
          toDate: formattedToDate,
          limit: 10,
        },
      })
        .then((response) => {
          this.list = response.data.data.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
          });
          this.totalData = response.data.totalLength;
          this.selectedDatesArray = this.list.map((item) => item.selectedDate);
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
<style>
.subheading {
  font-family: mainfont;
  color: rgb(13, 154, 69);
  text-transform: none;
  font-size: 17px;
}

.heading {
  font-family: mainfont;
  color: rgb(229, 111, 8);
  text-transform: none;
  font-size: 25px;
}
</style>